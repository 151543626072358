<template>
  <div style="top: 0; position: fixed; width: 100%; z-index: 10000">
    <div class="banner" :class="{ fff: !popShow }">
      <img src="@/assets/img/mobile/logo.png" alt="" class="logo" />
      <img
        v-if="popShow"
        @click="popShow = !popShow"
        class="rightIcon1"
        src="@/assets/img/mobile/icon777.png"
        alt=""
      />
      <!--			<van-icon-->
      <!--				name="wap-nav"-->
      <!--				size="16px"-->
      <!--				class="rightIcon1"-->
      <!--				@click="popShow = !popShow"-->
      <!--				v-if="popShow"-->
      <!--			/>-->
      <img
        v-else
        @click="popShow = !popShow"
        class="rightIcon"
        src="@/assets/img/mobile/icon_close.png"
        alt=""
      />
      <!--			<van-icon-->
      <!--				v-else-->
      <!--				name="cross"-->
      <!--				class="rightIcon"-->
      <!--				@click="popShow = !popShow"-->
      <!--				size="18px"-->
      <!--			/>-->
      <div class="cav" v-show="!popShow">
        <ul>
          <li
            class="routerLink"
            @click="routerGo('/mobile/home')"
            :class="{ bannerColor: $route.path == '/mobile/home' }"
          >
            首页
          </li>
          <li
            class="routerLink"
            @click="routerGo('/mobile/trends')"
            :class="{ bannerColor: $route.path == '/mobile/trends' }"
          >
            招聘动态
          </li>
          <a href="https://nio.jobs.feishu.cn/campus" target="_blank"
            ><li class="routerLink" @click="newWork()">职位投递</li></a
          >
        </ul>
      </div>
    </div>
    <div class="masking" v-if="!popShow" @click="popShow = !popShow"></div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Banner",

  data() {
    return {
      popShow: true,
    };
  },

  mounted() {},

  methods: {
    routerGo(url) {
      this.$router.push(url);
    },
    newWork() {
      window.open("https://nio.jobs.feishu.cn/campus", "_blank");
      // newTab.location.href ="https://nio.jobs.feishu.cn/campus";
      // window.open('https://nio.jobs.feishu.cn/internpro')
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 2.75rem;
  background-color: rgba(245, 246, 248, 0.5);
  backdrop-filter: blur(20px);
  line-height: 2.75rem;
  position: absolute;
  font-size: 1rem;
  font-weight: 400;
  .logo {
    height: 2rem;
    width: 2rem;
    left: 4%;
    top: 20%;
    position: absolute;
  }
  .rightIcon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    right: 4%;
    top: 26%;
    position: absolute;
  }
  .rightIcon1 {
    width: 24px;
    height: 24px;
    object-fit: cover;
    right: 4%;
    top: 26%;
    position: absolute;
  }
  .cav {
    padding: 20px;
    width: 100%;
    height: 10rem;
    background-color: #fff;
    margin-top: 2.75rem;
    position: fixed;
    width: 100%;
    z-index: 10000;
    .routerLink {
      width: 100%;
      color: #040b29;
    }
    .routerLink.bannerColor {
      color: #00b3be;
    }
  }
}
.masking {
  position: fixed;
  top: 12.75rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.fff {
  background-color: #fff !important;
}
a {
  color: #040b29;
  //cursor: pointer;
}
</style>
